import ContactForm from "@/components/common/ContactForm";
import FeatureList from "@/components/FeatureList";
import FeatureListItem from "@/components/FeatureListItem";
import Layout from "@/components/layout/Layout";
import Section from "@/components/layout/Section";
import SectionGreen from "@/components/layout/SectionGreen";
import SectionTitle from "@/components/layout/SectionTitle";
import List from "@/components/List";
import ListItemOrdered from "@/components/ListItemOrdered";
import Testimonial from "@/components/Testimonial";
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Page() {
  const { t } = useTranslation("common");

  return (
    <Layout>
      <GatsbySeo
        title={t("employer.seo.title")}
        description={t("employer.seo.description")}
      />

      {/* Hero */}
      <SectionTitle
        subtitle={t("employer.sectionMain.subtitle")}
        title={t("employer.sectionMain.title")}
      >
        {t("employer.sectionMain.description")}
      </SectionTitle>

      {/* Features */}
      <Section
        subtitle={t("employer.sectionFeatures.subtitle")}
        title={t("employer.sectionFeatures.title")}
        description={t("employer.sectionFeatures.description")}
        isTagReversed={true}
        is35={true}
      >
        <FeatureList rows={3}>
          <FeatureListItem name={t("employer.sectionFeatures.feature1.label")}>
            {t("employer.sectionFeatures.feature1.description")}
          </FeatureListItem>
          <FeatureListItem name={t("employer.sectionFeatures.feature2.label")}>
            {t("employer.sectionFeatures.feature2.description")}
          </FeatureListItem>
          <FeatureListItem name={t("employer.sectionFeatures.feature3.label")}>
            {t("employer.sectionFeatures.feature3.description")}
          </FeatureListItem>
          <FeatureListItem name={t("employer.sectionFeatures.feature4.label")}>
            {t("employer.sectionFeatures.feature4.description")}
          </FeatureListItem>
          <FeatureListItem name={t("employer.sectionFeatures.feature5.label")}>
            {t("employer.sectionFeatures.feature5.description")}
          </FeatureListItem>
          <FeatureListItem name={t("employer.sectionFeatures.feature6.label")}>
            {t("employer.sectionFeatures.feature6.description")}
          </FeatureListItem>
        </FeatureList>
      </Section>

      {/* Jak działamy */}
      <Section
        subtitle={t("employer.sectionSteps.subtitle")}
        title={t("employer.sectionSteps.title")}
        description={t("employer.sectionSteps.description")}
        isTagReversed={true}
        is35={true}
      >
        <List>
          <ListItemOrdered number="1.">
            {t("employer.sectionSteps.step1")}
          </ListItemOrdered>
          <ListItemOrdered number="2.">
            {t("employer.sectionSteps.step2")}
          </ListItemOrdered>
          <ListItemOrdered number="3.">
            {t("employer.sectionSteps.step3")}
          </ListItemOrdered>
          <ListItemOrdered number="4.">
            {t("employer.sectionSteps.step4")}
          </ListItemOrdered>
          <ListItemOrdered number="5.">
            {t("employer.sectionSteps.step5")}
          </ListItemOrdered>
          <ListItemOrdered number="6.">
            {t("employer.sectionSteps.step6")}
          </ListItemOrdered>
        </List>
      </Section>

      {/* Zielona sekcja */}
      <SectionGreen>
        <SectionTitle
          subtitle={t("employer.sectionTestimonials.subtitle")}
          title={t("employer.sectionTestimonials.title")}
          tag="h2"
          isInverted={true}
        >
          {t("employer.sectionTestimonials.description")}
        </SectionTitle>
        <Section
          subtitle={t("employer.sectionTestimonials.testimonial1.subtitle")}
          title={t("employer.sectionTestimonials.testimonial1.title")}
          description={t(
            "employer.sectionTestimonials.testimonial1.description"
          )}
          tag="h3"
          isInverted={true}
          isTagReversed={true}
          is35={true}
        >
          <Testimonial
            author={t("employer.sectionTestimonials.testimonial1.author")}
            position={t("employer.sectionTestimonials.testimonial1.position")}
          >
            {t("employer.sectionTestimonials.testimonial1.testimonial")}
          </Testimonial>
        </Section>
        <Section
          subtitle={t("employer.sectionTestimonials.testimonial2.subtitle")}
          title={t("employer.sectionTestimonials.testimonial2.title")}
          description={t(
            "employer.sectionTestimonials.testimonial2.description"
          )}
          tag="h3"
          isInverted={true}
          isTagReversed={true}
          is35={true}
        >
          <Testimonial
            author={t("employer.sectionTestimonials.testimonial2.author")}
            position={t("employer.sectionTestimonials.testimonial2.position")}
          >
            {t("employer.sectionTestimonials.testimonial2.testimonial")}
          </Testimonial>
        </Section>
      </SectionGreen>

      {/* Kontakt */}
      <Section
        subtitle={t("employer.sectionContact.subtitle")}
        title={t("employer.sectionContact.title")}
        description={t("employer.sectionContact.description")}
        is35={true}
      >
        <ContactForm />
      </Section>
    </Layout>
  );
}
